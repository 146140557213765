<template>
  <div>
    <!-- The file input for influencers list -->
    <input
      type="file"
      name="upload-influencers-file"
      accept=".csv, .xlsx, .xls"
      class="d-none"
      ref="influencersFileUploadInput"
      @change="handleFileChange"
    />

    <!-- Show the dialog box to add influencers -->
    <v-dialog
      :value="value"
      @input="$emit('input', $event)"
      max-width="500"
      :persistent="shouldFormBeDisabled"
    >
      <v-card>
        <v-card-title>
          <div class="d-flex justify-space-between align-center width-100">
            <!-- Show the text -->
            <div>Add Influencers</div>

            <div class="d-flex align-center">
              <!-- Show the button to download example sheet -->
              <v-btn text depressed class="mr-3" color="primary" :disabled="shouldFormBeDisabled" @click="downloadSampleSheet">
                <v-icon left> download </v-icon>

                Sample
              </v-btn>

              <!-- Show the button to upload in bulk -->
              <v-btn
                depressed
                color="primary"
                :disabled="shouldFormBeDisabled"
                :loading="isUploading"
                @click="$refs.influencersFileUploadInput && $refs.influencersFileUploadInput.click()"
              >
                <v-icon left> file_upload </v-icon>

                Upload
              </v-btn>
            </div>
          </div>
        </v-card-title>

        <v-card-text class="accent pt-6">
          <div class="d-flex">
            <!-- Show the input for searching terms -->
            <div class="search-input flex-grow-1 overflow-hidden">
              <profile-selector
                :key="refreshKey"
                @change="handleProfileChange"
                :platform="selectedPlatform"
                :use-combobox="true"
                :hide-no-data="true"
                type="search"
                label="Search Profile"
                :disabled="shouldFormBeDisabled"
                class="rounded-r-0"
                flat
                solo
              />
            </div>

            <!-- The select options for different platforms -->
            <platform-selector
              :module="module"
              :overview="overview"
              :show-all="false"
              :only-icons="true"
              :disabled="shouldFormBeDisabled"
              @input="handlePlatformChange"
              class="rounded-l-0"
            />
          </div>
        </v-card-text>

        <v-card-actions>
          <v-btn
            v-if="isInfluencerGroupsAllowed"
            text
            color="primary"
            :disabled="shouldFormBeDisabled"
            @click="shouldShowInfluencerGroupsForm = true"
          >
            <v-icon left>import_export</v-icon>

            Import Group
          </v-btn>

          <v-spacer></v-spacer>

          <!-- Show the cancel button -->
          <v-btn
            text
            :disabled="shouldFormBeDisabled"
            @click="$emit('input', false)"
          >
            Cancel
          </v-btn>

          <!-- Show the submit form button -->
          <v-btn
            depressed
            color="primary"
            @click="doSubmitForm"
            :disabled="shouldFormBeDisabled"
            :loading="isMakingRequest"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <influencer-groups-search
      v-model="shouldShowInfluencerGroupsForm"
      :return-selected="true"
      @change="handleInfluencerGroupChange"
    />
  </div>
</template>

<script>
// Import node package
const CSV = require("csv")
const XLSX = require("xlsx")

// Import helper functions
import { required, maxLength, minLength } from "vuelidate/lib/validators"
import csvToJson from "@/helpers/csvToJson"
import sheetToJson from "@/helpers/sheetToJson"
import platformRegex from "@/helpers/platformRegex"

// Import children components
const ProfileSelector = () => import(/* webpackChunkName: "profile-selector" */ "@/blocks/common/selectors/ProfileSelector.vue")
const PlatformSelector = () => import(/* webpackChunkName: "platform-selector" */ "@/components/crm/PlatformSelector.vue")
const InfluencerGroupsSearch = () => import(/* webpackChunkName: "influencer-groups-search" */ "@/components/crm/influencer-groups/Search.vue")

// Define the generator function for formData
const generateFormData = () => ({
  platform: "instagram",
  username: ""
})

// Export the SFC
export default {
  // Name of the component
  name: "AddInfluencers",

  // Register children components
  components: {
    ProfileSelector,
    PlatformSelector,
    InfluencerGroupsSearch
  },

  // Define incoming props
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    overview: {
      type: Object,
      required: true,
    },

    selectedPlatform: {
      type: String,
      default: "instagram",
    },

    // The Vuex store action to call
    module: {
      type: String,
      required: true
    },

    includePostsInput: {
      type: Boolean,
      default: false
    }
  },

  // Define local data variables
  data: () => ({
    // If the form is being submitted
    isMakingRequest: false,

    // If it's uploading the spreadsheet file
    isUploading: false,

    // Store the form data for the request
    formData: generateFormData(),

    // Refresh key for profile selector
    refreshKey: Symbol(),

    // Module and API endpoint map
    moduleMap: {
      influencerGroups: "influencer-groups",
      campaignTracking: "campaign-tracking",
      influencerOutreach: "influencer-outreach"
    },

    // Whether or not to show the influencer groups form
    shouldShowInfluencerGroupsForm: false
  }),

  // Define local computable properties
  computed: {
    /**
     * If the code is making a network request, disable the buttons and inputs
     *
     * @returns {Boolean}
     */
    shouldFormBeDisabled() {
      return this.isMakingRequest || this.isUploading
    },

    /**
     * Whether or not to show the influencer groups form
     *
     * @return {Boolean}
     */
    isInfluencerGroupsAllowed() {
      return this.$store.getters['auth/isServiceAllowed'](constants.model.user.allowedServices.influencerGroups)
    },
  },

  // Define vuelidate validations object
  validations: {
    formData: {
      username: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(200)
      }
    }
  },

  // Define local method functions
  methods: {
    /**
     * Handle the file input change for bulk influencers upload
     *
     * @param {Object} event
     * @returns {void}
     */
    handleFileChange(event) {
      // Check if the event has any files in it
      if (!event.target.files || !event.target.files.length) {
        // Show an error message
        this.$store.dispatch("toasts/add", { text: "No file selected" })

        // End the execution
        return
      }

      // Get the file object
      const file = event.target.files[0]

      // Validate the mime type of this file is CSV
      if (
        !["text/csv", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"].includes(file.type)
      ) {
        // Show an error message
        this.$store.dispatch("toasts/add", { text: "File is not a CSV or XLSX" })

        // End the execution
        return
      }

      // Show a loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isUploading = true

      // Define a function to hide the loaders
      const doHideLoaders = () => {
        this.$store.dispatch("loaders/remove", loaderId)
        this.isUploading = false
      }

      // Define a function to extract URLs and make network request
      const postProcessor = async (result) => {
        // Get all the URLs from the CSV file
        const URLs = {
          instagram: [],
          youtube: [],
          tiktok: [],
          snapchat: []
        }

        const supportedPlatforms = []
        if (this.overview.model.platforms.includes("instagram")) {
          supportedPlatforms.push("instagram")
        }
        if (this.overview.model.platforms.includes("youtube")) {
          supportedPlatforms.push("youtube")
        }
        if (this.overview.model.platforms.includes("tiktok")) {
          supportedPlatforms.push("tiktok")
        }
        if (this.overview.model.platforms.includes("snapchat")) {
          supportedPlatforms.push("snapchat")
        }

        // Keep a count of URLs
        let counter = 0

        // Get the rows
        const rows = Array.isArray(result) ? result : []

        // Go through each row
        for (const row of rows) {
          // If the row is an array
          const headers = Array.isArray(row) ? row : Object.keys(row || {})
          const columns = Array.isArray(row) ? row : Object.values(row || {})

          // The value to push
          let platformToPushTo = null
          const valueToPush = {
            username: "",
          }

          // If we should include posts
          if (this.includePostsInput) {
            valueToPush.expected_posts = 1
          }

          // Go through each column
          for (const column of columns) {
            // If the column is empty
            if (!column) {
              continue
            }

            // Get the value
            const value = column.trim()

            // Go through each platform
            for (const platform of supportedPlatforms) {
              // Check if the value is valid
              const match = value.match(platformRegex[platform])

              // If match found
              if (match !== null && match[1]) {
                // Set the value
                platformToPushTo = platform
                valueToPush.username = match[1]

                // Increment the counter
                counter++
              }
            }
          }

          // If we should include posts
          if (this.includePostsInput) {
            // Go through each header
            for (const header of headers) {
              // If the header contains these words
              if (header.toLowerCase().includes("post") && header.toLowerCase().includes("count")) {
                // Get the value
                const value = columns[headers.indexOf(header)]

                // If the value is a number
                if (!Number.isNaN(value)) {
                  // Set the value
                  valueToPush.expected_posts = parseInt(value)
                }
              }
            }
          }

          // If we have the platform to push to
          if (platformToPushTo) {
            // Push the value
            URLs[platformToPushTo].push(valueToPush)
          }
        }

        // If there are no profile links
        if (counter === 0) {
          // Show an error message
          this.$store.dispatch("toasts/add", { text: "No valid URL found in this CSV file" })

          // Also hide the loaders
          doHideLoaders()

          // End the execution
          return
        }
        // Otherwise, if there are more than 20,000 URLs
        else if (counter > 20_000) {
          // Show an error message
          this.$store.dispatch("toasts/add", { text: "You cannot add more than 20,000 profiles at once" })

          // Also hide the loaders
          doHideLoaders()

          // End the execution
          return
        }
        // Finally
        else {
          // Make network request
          await this.uploadInfluencers(URLs)
          // Hide the loaders
          doHideLoaders()
        }
      }

      // Otherwise, try to read the file
      const reader = new FileReader()

      // Add a callback function on it
      reader.addEventListener("load", async () => {
        // If the file type is CSV
        if (file.type === "text/csv") {
          // Try parsing the text as a CSV document
          CSV.parse(reader.result, async (error, result) => {
            // If there's any error
            if (error) {
              // Log the error
              logger({ type: "CSV Parse Error", error })

              // Show an error message
              this.$store.dispatch("toasts/add", { text: "File is not a valid CSV document" })

              // Also hide the loaders
              doHideLoaders()

              // End the execution
              return
            }
            // Otherwise
            else {
              const items = csvToJson(result)

              // Use the helper function
              postProcessor(items)
            }
          })
        }
        // Otherwise
        else {
          const buffer = reader.result

          // Load the file as XLSX
          const workbook = XLSX.read(buffer)
          // Translate the XLSX into a JSON object
          const items = sheetToJson(workbook.Sheets[workbook.SheetNames[0]])

          // Use the helper function
          postProcessor(items)
        }
      })

      // If the file is of type CSV
      if (file.type === "text/csv") {
        // Read the file as a text
        reader.readAsText(file)
      }
      // Otherwise
      else {
        // Read the file as array buffer
        reader.readAsArrayBuffer(file)
      }
    },

    /**
     * Every time the user selects or submits the user input form, call this function
     *
     * @param {Object} selectedUser
     * @return {void}
     */
    handleProfileChange(selectedUser) {
      // If the search query is empty
      if (!selectedUser) return false

      // Check if the value is a URL
      if (isURL(selectedUser)) {
        // Try to get the username from it
        const match = selectedUser.match(platformRegex[this.formData.selectedPlatform])

        // If the regex worked
        if (match !== null && match[1]) {
          // Also update the value of the form input
          this.formData.username = match[1]

          // return to make sure the function ends here
          return true
        }
      }
      // Otherwise
      else {
        this.formData.username = selectedUser
      }
    },

    /**
     * Whenever the user switches the platform selected, reset the form
     *
     * @param {String} value
     * @returns {void}
     */
    handlePlatformChange(value) {
      // Reset the username value
      this.formData.username = null

      // Update the platform value
      this.formData.platform = value
    },

    /**
     * Try to validate and then submit the form
     *
     * @returns {void}
     */
    async doSubmitForm() {
      // Run the validations
      await this.$v.formData.$touch()

      // If there's any error
      if (this.$v.formData.$anyError) {
        // Stop the execution
        return
      }

      // Otherwise, show a loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingRequest = true

      // Make the network request
      try {
        // Compute the value to send
        let value = this.formData.username
        let isPreviewId = false

        // If it's an object
        if (typeof value === "object") {
          // If we have a preview_id
          if (value.preview_id) {
            value = value.preview_id
            isPreviewId = true
          }
          // Otherwise
          else {
            value = value.nameToShow
          }
        }

        await axios({
          url: `/api/${this.moduleMap[this.module]}/${this.overview.model.id}/influencers`,
          method: "POST",
          data: {
            platform: this.selectedPlatform,
            is_preview_id: isPreviewId,
            value
          }
        })

        // If succeeded, show a toast message
        this.$store.dispatch("toasts/add", { text: "Influencer added!" })

        // Reset the form
        this.formData = generateFormData()

        // Refresh the key
        this.refreshKey = Symbol()

        // Fetch the data again
        this.$emit("refresh")

        // Also hide the dialog
        this.$emit("input", false)
      } catch (error) {
        // Catch the error
        // Log using the helper function
        logger({ type: "Network Error", error })

        // Show a toast message
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred" })
      } finally {
        // At last, hide the loaders
        this.$store.dispatch("loaders/remove", loaderId)
        this.isMakingRequest = false
      }
    },

    /**
     * Make the network request to bulk upload
     *
     * @param {Object} data
     * @returns {void}
     */
    async uploadInfluencers(data) {
      // Try to make the network request
      try {
        await axios({
          url: `/api/${this.moduleMap[this.module]}/${this.overview.model.id}/influencers/bulk`,
          method: "POST",
          data: data
        })

        // If succeeded, show a toast message
        this.$store.dispatch("toasts/add", { text: "Influencers are being added, the list will update shortly!" })

        // Reset the form
        this.$refs.influencersFileUploadInput.value = null

        // Also hide the dialog
        this.$emit("input", false)
      } catch (error) {
        // Catch the error
        // Log using the helper function
        logger({ type: "Network Error", error })

        // Show an error message
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "Could not upload influencers, please try again!" })
      }
    },

    /**
     * Generate and download a sample XLSX sheet file
     *
     * @returns {void}
     */
    downloadSampleSheet() {
      // To disable the buttons
      this.isMakingRequest = true

      // Define the rows
      const rows = [
        ["Profile URL", "Expected Posts Count"],
        ["https://www.instagram.com/username", 1],
        ["https://www.youtube.com/@username", 4],
        ["https://www.tiktok.com/@username", 7],
        ["https://www.snapchat.com/add/username", 2]
      ]

      // If we should not include posts
      if (!this.includePostsInput) {
        // Remove the last column
        rows.forEach(row => row.pop())
      }

      // Initiate a workbook instance
      const workbook = XLSX.utils.book_new()

      // Create the worksheet
      const worksheet = XLSX.utils.json_to_sheet([])

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet)

      // Add headers
      XLSX.utils.sheet_add_aoa(worksheet, rows)

      // Style with width
      worksheet["!cols"] = Array(10).fill({ wch: 16 })

      // Download this instance
      XLSX.writeFile(workbook, `Influencers Sample Sheet - ${this.host.name}.xlsx`)

      // Enable the buttons again
      this.isMakingRequest = false
    },

    /**
     * Handle the influencer group change
     *
     * @param {Object} selectedGroup
     * @returns {void}
     */
    async handleInfluencerGroupChange(selectedGroup) {
      // Close the dialogs
      this.shouldShowInfluencerGroupsForm = false
      this.$emit('input', false)

      // Show a loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)

      try {
        // Make the network request
        await axios({
          url: `/api/${this.moduleMap[this.module]}/${this.overview.model.id}/influencers/copy/${selectedGroup.id}`,
          method: "POST",
        })

        // Show a toast message
        this.$store.dispatch("toasts/add", { text: "Influencers added to group successfully" })

        // Trigger refresh
        this.$emit("refresh")
      } catch (error) {
        // Log using the helper function
        logger({ type: "AddInfluencers/handleInfluencerGroupChange", error })

        // Show an error message
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "Could not copy influencers, please try again!" })
      } finally {
        // At last, hide the loaders
        this.$store.dispatch("loaders/remove", loaderId)
      }
    }
  }
};
</script>
